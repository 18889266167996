/* Montserrat Normal */
@font-face {
  font-family: "Montserrat";
  font-weight: normal;
  src: url("./fonts/Montserrat/Montserrat-Light.ttf") format("opentype");
}
/* Montserrat Bold */
@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  src: url("./fonts/Montserrat/Montserrat-Bold.ttf") format("opentype");
}
/* Montserrat Regular */
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("opentype");
}
/* Montserrat Medium */
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: url("./fonts/Montserrat/Montserrat-Medium.ttf") format("opentype");
}
/* Poppings SemiBold */
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("opentype");
}
